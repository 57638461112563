/**
 * Theme.js
 */

import '../scripts/accessibility'
import '../scripts/aos'
import '@/scripts/flying-scripts'
import { getWishlist } from '@/services/wishlist'
import revive from '@/scripts/revive'

// Islands
const islands = import.meta.glob('../../modules/**/*.(js|jsx)')
revive(islands)

// globals
;(async () => {
  // wishlist
  if (window.BARREL.customerId) {
    window.BARREL.wishlist = await getWishlist()
    const event = new window.CustomEvent('wishlistLoaded', { detail: window.BARREL.wishlist })
    setTimeout(() => {
      window.document.dispatchEvent(event)
    }, 500)

  }
})()

